// Third party
import React from "react";
import * as PropTypes from "prop-types";
import moment from "moment";
import styled from "styled-components";

// Proprietary
import Avatar from "../components/Avatar";

const Wrapper = styled.div`
  overflow: hidden;
  img {
    float: left;
    margin-right: 15px;
  }
  p {
    margin-bottom: 0;
  }
`;

const Author = props => {
  const {
    avatar: {
      fixed: { src },
    },
    title,
    date,
  } = props;
  return (
    <Wrapper>
      <Avatar src={src} size="small" alt={title} title={title} loading="lazy" />
      <p>
        door <strong>{title}</strong>
      </p>
      {date && (
        <p>
          <small>{moment(date).format("MMM Do YYYY")}</small>
        </p>
      )}
    </Wrapper>
  );
};

Author.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

export default Author;
